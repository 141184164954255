import { Card, Col, InputNumber, message, Modal, ModalProps, Row, Select } from 'antd'
import React, { FC, Fragment, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import notification, { IconType } from 'antd/lib/notification'

import useDataListOnSnapshot from '../../../../hooks/useDataListOnSnapshot'
import User from '../../../../model/User'
import { selectuser } from '../../../../redux/userSlice'
import { callServiceTable, getShiftOpening, getStaffListItems, getTablesList } from '../../../../services/FirebaseFirestoreService'
import Table from '../../../../model/Table'
import { useHistory, useLocation } from 'react-router-dom'
import { RootState, useAppDispatch } from '../../../../redux/store'
import { addNumberPeople, resetTable } from '../../../../redux/tableSlice'
import Title from 'antd/lib/typography/Title'
import { ReactComponent as CustomerWhite } from '../../../../assets/icons/customer-white.svg'
import Utils, { numberWithCommas } from '../../../../helper/utils'
import ButtonCustom from '../../../../components/shared-components/ButtonCustom'
import { ReactComponent as BellWhite } from '../../../../assets/icons/bell-white.svg'
import { ReactComponent as BillWhite } from '../../../../assets/icons/bill-white.svg'
import { selectStaff } from '../../../../redux/staffSlice'
import { setCustomerMode, setLanguage, setLastScreen } from '../../../../redux/customerSlice'

interface TableListProps {
    resIdProp?: string | null
}

const TableList: FC<TableListProps> = ({ resIdProp }) => {
    const currentUser: User = useSelector(selectuser)
    const currentStaff: any = useSelector(selectStaff)
    const resId = resIdProp ? resIdProp : currentUser.id_restaurant
    const [error, loading, tableList]: any = useDataListOnSnapshot(resId, getTablesList)
    const { pathname } = useLocation()
    const [errorStaffListItems, loadingStaffListItems, StaffListItems]: any = useDataListOnSnapshot(resId, getStaffListItems)
    const [errorShiftOpenItem, loadingShiftOpenItem, shiftOpenItem]: any = useDataListOnSnapshot(resId, getShiftOpening, currentStaff?.staffCode)
    const [isOpenShift, setIsOpenShift] = useState(false)

    useEffect(() => {
        // clear localStorage
        window.localStorage.clear()
    }, [])

    useEffect(() => {
        if (loadingStaffListItems) return
        if (!StaffListItems) return
    }, [StaffListItems])

    useEffect(() => {
        if (error) notificationErrorLoading('error')
    }, [error])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    useEffect(() => {
        if (errorShiftOpenItem) notificationErrorLoading('error')

        if (shiftOpenItem && shiftOpenItem?.length > 0) {
            setIsOpenShift(true)
        } else {
            setIsOpenShift(false)
        }
    }, [shiftOpenItem, errorShiftOpenItem, loadingShiftOpenItem])

    const notificationErrorLoading = (type: IconType) => {
        notification[type]({
            message: 'Notification',
            description: 'Cannot load data table',
        })
    }

    return (
        <Fragment>
            <div className="table-list-container">
                <Row gutter={10} style={{ marginTop: resIdProp ? '0' : '65px' }}>
                    {loading ? (
                        <Col span={24}>
                            <Card loading={loading} />
                        </Col>
                    ) : (
                        <Fragment>
                            {tableList
                                .sort((a: any, b: any) => a.created.seconds - b.created.seconds)
                                .map((item: Table) => (
                                    <Col key={item.id} xs={{ span: 6 }} sm={{ span: 6 }}>
                                        <CardTable resId={resId} table={item} isOpenShift={isOpenShift} />
                                    </Col>
                                ))}
                        </Fragment>
                    )}
                </Row>
            </div>
        </Fragment>
    )
}

export default TableList

interface CardTableProps {
    resId: string
    table: Table
    isOpenShift: boolean
}

const CardTable: FC<CardTableProps> = (props) => {
    const { table, resId, isOpenShift } = props
    let history = useHistory()
    const location = useLocation()
    const [errorStaffListItems, loadingStaffListItems, StaffListItems]: any = useDataListOnSnapshot(resId, getStaffListItems)
    const [listStaffUser, setListStaffUser] = useState<any[]>([])
    const [modalEditOpen, changeEditModalOpen] = useState(false)
    const [numberPeople, setNumberPeople] = useState<any>(table.number_people ? table.number_people : 0)
    const dispatch = useAppDispatch()
    const [isShowInput, setIsShowInput] = useState<boolean>(true)
    const [showModalOpenShift, setShowModalOpenShift] = useState(false)
    const isCustomerApp = useSelector((store: RootState) => store.customer.isCustomerApp)
    const callingPayAudio = new Howl({
        src: ['/sound/callingpay.wav'],
    })

    useEffect(() => {
        if (table && table?.notify) {
            callingPayAudio.play()
        }
    }, [table])

    useEffect(() => {
        setNumberPeople(table.number_people ? table.number_people : 0)
    }, [modalEditOpen])

    useEffect(() => {
        dispatch(resetTable())
    }, [])

    useEffect(() => {
        if (loadingStaffListItems) return
        if (!StaffListItems) return
        setListStaffUser(StaffListItems)
    }, [StaffListItems])
    const handleCancelModalEdit = () => {
        changeEditModalOpen(false)
        setNumberPeople(null)
    }

    const handleConfirmModalEdit = () => {
        if (!isCustomerApp) {
            if (!numberPeople || numberPeople === 0 || numberPeople === '0') {
                return message.error('お客様の人数をお選びください.')
            }
        }

        dispatch(
            addNumberPeople({
                number_people: numberPeople,
                table_id: table.id,
                table_name: table.name,
            })
        )

        changeEditModalOpen(false)
        if (isCustomerApp) {
            dispatch(setCustomerMode(true))
            dispatch(setLastScreen(`/customer/${resId}/${table.id}/${numberPeople}/${table.qrcode}`))
            return history.push(`/customer/${resId}/${table.id}/${numberPeople}/${table.qrcode}`)
        }
        return history.push(`/app/tables/detail/${table.id}`)
    }

    const handleCallService = async () => {
        await callServiceTable(resId, table.id, {
            notify: false,
        })
    }

    const handleCallPayBill = async () => {
        await callServiceTable(resId, table.id, {
            isPaid: false,
        })
    }

    const ModalEditProps: ModalProps = {
        title: 'お客様の人数',
        visible: modalEditOpen,
        closeIcon: ' ',
        onCancel: handleCancelModalEdit,
        className: 'modal-confirm-input-number-people',
        footer: [
            isCustomerApp ? (
                <></>
            ) : (
                <ButtonCustom
                    active={true}
                    className="active"
                    onClick={() => {
                        dispatch(setCustomerMode(true))
                        dispatch(setLastScreen(`/customer/${resId}/${table.id}/${numberPeople}/${table.qrcode}`))
                        history.push(`/customer/${resId}/${table.id}/${numberPeople}/${table.qrcode}`)
                    }}
                    style={{ borderRadius: '30px', background: 'linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%)', minWidth: '144px', fontSize: '14px' }}
                >
                    お客様画面
                </ButtonCustom>
            ),
            <Title level={3} style={{ marginTop: '5px' }} className={'customer'}>
                {isShowInput ? (
                    <InputNumber
                        type="number"
                        onChange={(e) => {
                            if (e && e > 0) {
                                setNumberPeople(Number(e))
                            } else {
                                setNumberPeople(0)
                            }
                        }}
                        value={numberPeople}
                    />
                ) : (
                    numberPeople
                )}
            </Title>,
            <ButtonCustom active={true} className="active" onClick={handleConfirmModalEdit} style={{ borderRadius: '30px', background: 'linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%)', minWidth: '144px', fontSize: '14px' }}>
                確認
            </ButtonCustom>,
        ],
    }

    const getButtonRow = () => {
        const numberOfRow = Array.from({ length: 15 }, (_, i) => i + 1)

        return numberOfRow.map((item) => {
            return (
                <Col style={{ marginLeft: '6px', textAlign: 'center' }} xs={{ span: 4, offset: 0.8 }}>
                    <ButtonCustom active={item == numberPeople} onClick={() => setNumberPeople(item)}>
                        {item}
                    </ButtonCustom>
                </Col>
            )
        })
    }
    const handleOpenTable = () => {
        if (!isOpenShift) {
            return setShowModalOpenShift(true)
        }

        if (isCustomerApp) {
            dispatch(setCustomerMode(true))
            dispatch(setLastScreen(`/customer/${resId}/${table.id}/${numberPeople}/${table.qrcode}`))
            return history.push(`/customer/${resId}/${table.id}/${numberPeople}/${table.qrcode}`)
        }

        if (table.is_used) {
            if (location.pathname.includes('customer')) return history.push(`/app/tables/home/${table.id}`)
            else if (!table.notify && !table.isPaid) {
                return history.push(`/app/tables/detail/${table.id}`)
            }
        } else {
            return changeEditModalOpen(true)
        }
    }

    const ModalPropsShift: ModalProps = {
        visible: showModalOpenShift,
        onCancel: () => {
            setShowModalOpenShift(false)
        },
        closeIcon: ' ',
        footer: null,
        className: 'modal-error-wrapper',
    }

    return (
        <Fragment>
            <Fragment>
                <div
                    onClick={handleOpenTable}
                    className={`table table-card-extra${table.is_used ? '-is-used' : ''}`}
                    style={{
                        overflow: 'hidden',
                    }}
                >
                    <div className="table__row">
                        <Title level={2}>{table.name}</Title>
                    </div>
                    <div className="table__row">
                        <Title level={2}>{table.total_amount ? numberWithCommas(table.total_amount) : numberWithCommas(0)}</Title>
                    </div>
                    <div className="table__row last">
                        <Title level={5}>{table.is_used ? Utils.getLocalTime(table.updatedAt).substring(0, 5) : ''}</Title>

                        <div
                            className="number-user"
                            onClick={(e) => {
                                e.stopPropagation()
                                changeEditModalOpen(true)
                            }}
                        >
                            <Title level={4}>{table.number_people ? table.number_people : 0}</Title>
                            <CustomerWhite className="icon-customer" />
                        </div>
                    </div>

                    {table.notify || table.isPaid ? (
                        <div
                            className={`overlay-card-call-service-icon ${table.notify && table.isPaid ? 'call-service-and-call-pay-bill' : ''}`}
                            onClick={() => {
                                table.notify && handleCallService()
                                table.isPaid && handleCallPayBill()
                            }}
                        >
                            {table.notify ? <BellWhite className="icon-bell" /> : <></>}
                            {table.isPaid ? <BillWhite className="icon-bill" /> : <></>}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                {modalEditOpen && (
                    <Modal {...ModalEditProps}>
                        <Row gutter={[0, 8]} justify={'center'} align={'middle'}>
                            {getButtonRow()}
                        </Row>
                    </Modal>
                )}
            </Fragment>

            <Modal {...ModalPropsShift}>
                <div className="d-flex justify-content-center">
                    <Title level={3}>まだ営業開始していません、先に営業開始してください</Title>
                </div>
            </Modal>
        </Fragment>
    )
}
