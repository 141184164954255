import React, { FC, useContext, useEffect, useState } from "react";
import { Drawer, DrawerProps, Layout, Row, Col, Button, Select } from "antd";
import Logo from "./Logo";
import utils from "../../utils";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH } from "../../constants/ThemeConstant";
import { HeaderNavProps } from "../../model/ui-components";
import NavProfile from "./NavProfile";
import { useSelector } from "react-redux";
import { selectuser } from "../../redux/userSlice";
import { ReactComponent as HistoryWhite } from "../../assets/icons/history-white.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import "../../App.css";
import Flex from "../shared-components/Flex";
import Scrollbars from "react-custom-scrollbars";
import Title from "antd/lib/typography/Title";
import User from "../../model/User";
import Text from "antd/lib/typography/Text";
import Utils from "../../helper/utils";
import useHistoryOrder from "../../hooks/useHistoryOrder";
import ButtonCustom from "../shared-components/ButtonCustom";
import { RootState, useAppDispatch } from "../../redux/store";
import { RestaurantContext } from "../../context/restaurantContext";
import { setLanguage } from "../../redux/customerSlice";
import { getRestaurantDetail } from "../../services/FirebaseFirestoreService";

const { Header } = Layout;

export const HeaderNav = (props: HeaderNavProps) => {
  const { isMobile, navCollapsed, navType, headerNavColor, currentTheme } = props;
  const currentUser: User = useSelector(selectuser);
  const resId = currentUser.id_restaurant;
  const [openHistoryOrderTable, openHistoryOrderTableOnchange] = useState(false);
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const [historyInShift, setHistoryInShift] = useState<any>([])
  const history = useHistoryOrder(resId)
  const [numberItem, setNumberItem] = useState(20);
  const isCustomerApp = false
  const language = useSelector((store: RootState) => store.customer.language)
  const dispatch = useAppDispatch();
  const [restaurant, setRestaurant] = useState<any>();

  useEffect(()=>{
    const fetch = async () =>{
      const fetchRestaurant = await getRestaurantDetail(resId);

      if (fetchRestaurant.exists()) {
        setRestaurant(fetchRestaurant.data());
      }
    }
    fetch();
  },[resId])
  

  useEffect(()=>{
    setHistoryInShift(history);
  },[history])

  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === "dark" ? "#00000" : "#ffffff");
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return
    }
  };

  const handleCloseDrawer = () => {
    openHistoryOrderTableOnchange(false);
  };

  const drawerProps: DrawerProps = {
    placement: "right",
    closable: false,
    visible: openHistoryOrderTable,
    onClose: handleCloseDrawer,
    className: "drawer-history-order-table",
    style: {
      zIndex: 101,
    },
    contentWrapperStyle: {
      position: "absolute",
      top: 60,
    },
    maskStyle: {
      position: "absolute",
      top: 60,
    },
    bodyStyle: {
      padding: "3px 6px",
    },
  };

  return (
    <>
      <Header className={`app-header ${navMode} app-header-height`} style={{ backgroundColor: "var(--primary-color)" }}>
        <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
          <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
            <div className="logo-restaurant">
              <NavProfile />
              <span> {currentUser.restaurant_name}</span>

            </div>
            <div className="nav-left nav-left-name">
              <div className="restaurant-name">
                <Logo logoType={navMode} navCollapsed={navCollapsed} navType={navType} />
              </div>
            </div>
            <div className="nav-right nav-right-avt">
            {restaurant?.listFoodLanguages?.length > 0 && 
              <Select style={{width: 45}} defaultValue={language} onChange={(value)=>{
                dispatch(setLanguage(value))
              }}>
                <Select.Option value=''><img width={20} src='https://th.bing.com/th/id/OIP.ii1uKyz0q4u32pQzS0iNTQHaFD?pid=ImgDet&rs=1' style={{marginRight: 10}}/></Select.Option>
                {restaurant?.listFoodLanguages?.map((item: any)=>{
                  return <Select.Option value={item.key}><img width={20} src={item.url} style={{marginRight: 10}}/></Select.Option>
                })}
                
              </Select>
            }
              {!isCustomerApp && (openHistoryOrderTable ? <Close onClick={() => {
                  setNumberItem(20)
                  openHistoryOrderTableOnchange(false)
                } } /> :
                  <HistoryWhite onClick={() => openHistoryOrderTableOnchange(!openHistoryOrderTable)} />)}
            </div>
          </div>
        </div>
      </Header>
      {openHistoryOrderTable &&
        <Drawer {...drawerProps}>
          <Flex flexDirection="column" className="h-100 mb-4">
            <Title level={4}>注文履歴</Title>
            <div className="mobile-nav-menu-new">
              {/* <Scrollbars autoHide> */}
                {
                  historyInShift?.length > 0 && historyInShift?.map((detail: any, index: number) => {
                    if(index > numberItem) return null
                    return <DetailItem key={index} table_name={detail.table_name} detail={detail} createdAt={detail.createdAt}/>
                  })
                }
                {numberItem < history.length && <Row style={{justifyContent: 'center'}}><Button className="primary-color" style={{fontWeight: 900}} onClick={()=>{setNumberItem(numberItem + 10)}}>＋もっと表示する</Button></Row>}
              {/* </Scrollbars> */}
            </div>
          </Flex>
        </Drawer>
      }
    </>
  );
};

export default HeaderNav;

const DetailItem: FC<any> = (props) => {
  
  return (
    <div className="detail-item-container">
      {
        props.detail.detail.length > 0 && props.detail.detail.map((item: any, index: number) => (
          <DetailComponent key={index} tableName={props.table_name} detailItem={item} createdAt={props.detail.createdAt}/>
        ))
      }
    </div>
  );
};

const DetailComponent: FC<any> = (props) => {
  const language = useSelector((store: RootState) => store.customer.language);
  const generateOptions = (item: any) => {
    const options: any[] = [];
    item?.options?.forEach((item: any) => {
      let name = item.name;

      item?.type?.forEach((type: any) => {
        if(type.isSelect){
          name += `: ${language && type?.nameLanguages && type?.nameLanguages[language] ? type?.nameLanguages[language].name : type?.name}`
        }
      })
      options.push(name);
    })

    return options.map(option => {
      return <p className="description-value">- {option}</p>
    })
  }

  const generateTopping = (item: any) => {
    const toppings: any[] = [];
    
    item?.toppings?.forEach((topping: any)=>{
      
      topping?.type?.forEach((type: any) => {
        if(type.quantity > 0){
          toppings.push(`${language && type?.nameLanguages && type?.nameLanguages[language] ? type?.nameLanguages[language].name : type?.name} x ${type.quantity}`)
        }
      })
    })

    return toppings.map(topping => {
      return <p className="description-value">- {topping}</p>
    })
  }

  
  return (
    <>
      <Row className="detail-item-row">
        <Col className="detail-item-table-name" span={6}>
          <Text>{props.tableName}</Text>
        </Col>
        <Col className="detail-item-food-name" span={12}>
        {/* <Text>{props?.detailItem?.name}</Text> */}
        <Text>
        {language && props?.detailItem?.nameLanguages && props?.detailItem?.nameLanguages[language] ? props?.detailItem?.nameLanguages[language].name : props?.detailItem?.name}
        {generateOptions(props?.detailItem)}
        {generateTopping(props?.detailItem)}
        </Text>
        </Col>
        <Col className="detail-item-food-quantity" span={2}>
          <Text>X {props?.detailItem?.quantity}</Text>
        </Col>
        <Col className="detail-item-food-time" span={4}>
          <Text>{Utils.getLocalTime(props?.createdAt)}</Text>
        </Col>
      </Row>
      <hr style={{ width: "100%" }} />
    </>
  );
};