import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomerApp, setCustomerAppId } from '../../../redux/customerSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { getRestaurantDetailByDeviceId, streamRestaurantDetailByDeviceId, updateRestaurantDevicesId } from '../../../services/FirebaseFirestoreService'
import TableList from './tables-list'
import { Button, Spin } from 'antd'
import { RootState } from '../../../redux/store'
import { Confirm } from 'semantic-ui-react'

const CustomerIframe = () => {
    const query = useQuery()
    // const customerAppId: string | null = query.get("device_id");
    const [customerAppId, setCustomerAppIdFunc] = useState(query.get('device_id'))
    const [resId, setResId] = useState<string | null | 'start'>('start')
    const [restaurant, setRestaurant] = useState<any | null>()
    const [loading, setLoading] = useState(false)
    const [no, setNo] = useState(1)
    const isCustomer = useSelector((store: RootState) => store.customer.isCustomer)
    const lastScreen = useSelector((store: RootState) => store.customer.lastScreen)
    const dispatch = useDispatch()
    const history = useHistory()
    const [newAppId, setNewAppId] = useState<any>()

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

    if (isCustomer) {
        history.push(lastScreen)
    }

    // const fetchData = async () => {
    //   setLoading(true);
    //   if (customerAppId) {
    //     const data: any = await getRestaurantDetailByDeviceId(customerAppId, false);

    //     if (data) {
    //       dispatch(setCustomerApp(true));
    //       dispatch(setCustomerAppId(customerAppId));
    //       setResId(data.id);
    //       setRestaurant(data);
    //     }
    //     setLoading(false);
    //   } else {
    //     setResId(null);
    //     setLoading(false);
    //   }
    // };

    const fetch = async () => {
        if (customerAppId) {
            const unsubscribe: any = streamRestaurantDetailByDeviceId(
                customerAppId,
                (data: any) => {
                    let no = 0
                    data?.data().devices.forEach((item: any, index: number) => {
                        if (item.code === customerAppId) no = index + 1
                    })
                    setNo(no)
                    if (no > 0) {
                        localStorage.setItem('no', no + '')
                    }
                    const device = data?.data().devices[no - 1]
                    if (device) {
                        dispatch(setCustomerApp(true))
                        dispatch(setCustomerAppId(customerAppId))
                        setResId(data.id)
                        setRestaurant(data.data())
                    } else {
                        setResId(null)
                        setLoading(false)
                    }
                },
                false
            )
            setLoading(false)
        } else {
            setResId(null)
            setLoading(false)
        }
    }

    useEffect(() => {
        // fetchData();
        fetch()
    }, [customerAppId])

    if (resId) {
        return (
            <>
                <div className="customer-app-header">
                    <Button
                        style={{
                            background: 'linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%)',
                        }}
                        className="btn-logout"
                        onClick={() => {
                            setShowDeleteConfirm(true)
                        }}
                    >
                        Logout
                    </Button>
                    <Confirm
                        header={'サイアウト'}
                        content={'サイアウトしますか?'}
                        open={showDeleteConfirm}
                        size="mini"
                        onCancel={() => {
                            setShowDeleteConfirm(false)
                        }}
                        onConfirm={async () => {
                            dispatch(setCustomerApp(false))
                            dispatch(setCustomerAppId(''))
                            setResId(null)
                            history.push('/auth/login')
                        }}
                    />
                    {/* {restaurant?.imageDefault && <Avatar src={restaurant.imageDefault} />} */}
                    <h1>{`No ${no}: ${customerAppId}`}</h1>
                </div>
                <TableList resIdProp={resId} />
            </>
        )
    } else if (loading) {
        return <Spin />
    } else if (resId === null) {
        return (
            <div style={{ marginTop: '20vh' }}>
                <Button
                    style={{ background: 'linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%)', display: 'block', marginLeft: '3%', fontWeight: '500' }}
                    onClick={() => {
                        dispatch(setCustomerApp(false))
                        dispatch(setCustomerAppId(''))
                        setResId(null)
                        history.push('/auth/login')
                    }}
                >
                    Logout
                </Button>

                <h1>{restaurant?.name}</h1>
                <h1>{`No ${localStorage.getItem('no')}: ${customerAppId}`}</h1>
                <img width={300} src="/img/others/logo-login.jpg" alt="" />
                <h1 style={{ padding: '0 20px', marginTop: 50, fontSize: 18 }}>コード変更しました。新しいコードを入力してください</h1>
                <h1 style={{ padding: '0 20px', fontSize: 18 }}>Mã không tồn tại hoặc đã hết hạn!</h1>

                <div>
                    <input
                        style={{
                            border: '1px solid black',
                            outline: 'none',
                            padding: '7px 20px',
                            marginBottom: 10,
                            borderRadius: 5,
                        }}
                        type="text"
                        value={newAppId}
                        onChange={(e) => {
                            setNewAppId(e.target.value)
                        }}
                        placeholder="New Code"
                    />
                </div>

                <Button
                    type="primary"
                    onClick={async () => {
                        const restaurant: any = await getRestaurantDetailByDeviceId(newAppId, true)
                        if (restaurant) {
                            await updateRestaurantDevicesId(
                                restaurant.id,
                                restaurant.devices.map((item: any) => {
                                    if (newAppId === item.code) item.active = false
                                    return {
                                        ...item,
                                    }
                                })
                            )
                        }
                        dispatch(setCustomerAppId(newAppId))
                        setCustomerAppIdFunc(newAppId)
                        history.push(`/customer-iframe?device_id=${newAppId}`)
                    }}
                >
                    確認
                </Button>

                <h1 style={{ marginTop: 30, fontSize: 18 }}>スタッフをお呼びください！</h1>
            </div>
        )
    } else {
        return <></>
    }
}

function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

export default CustomerIframe
