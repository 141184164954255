import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface HodaiReduxProps {
  stateCloseHodaiAlert: boolean;
  categories: any[]
}

const initialState: HodaiReduxProps = {
  stateCloseHodaiAlert: false,
  categories: [],
};

export const hodaiSlice = createSlice({
  name: "hodaiReducer",
  initialState,
  reducers: {
    resetHodai: () => {
      return initialState;
    },
    onChangeStateCloseHodaiAlert: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        stateCloseHodaiAlert: action.payload,
      };
    },   
     setCategories: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        categories: action.payload,
      };
    },
  },
});
export const getStateCloseHodaiAlert = (rootState: RootState) => rootState.hodai;
export const selectStateHodaiAlert = (rootState: RootState) => getStateCloseHodaiAlert(rootState).stateCloseHodaiAlert;
export const { resetHodai, onChangeStateCloseHodaiAlert, setCategories} = hodaiSlice.actions;
export default hodaiSlice.reducer;
