import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState: any = {
   staffCode: ""
};

export const staffSlice = createSlice({
    name: 'staffReducer',
    initialState,
    reducers: {
        setStaffCode: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                staffCode: action.payload.staffCode,
            }
        },
        clearStaffCode: state => {
            return {
                ...initialState
            }
        },
    }
})

export const selectStaff = (rootState: RootState) => rootState.staff;
export const { setStaffCode, clearStaffCode } = staffSlice.actions;
export default staffSlice.reducer;