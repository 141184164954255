import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getRestaurantDetailStream, streamOrderById, streamPaymentScreen } from '../../../services/FirebaseFirestoreService'
import './index.css'
import Utils, { convertHexToRGBA, numberWithCommas } from '../../../helper/utils'
import { Col, Row } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const PaymentScreen = () => {
    const params: any = useParams()
    const resId = params.resId
    const [orderId, setOrderId] = useState<string | null>()
    const [order, setOrder] = useState<any>()
    const [restaurant, setRestaurant] = useState()
    useEffect(() => {
        streamPaymentScreen(resId, (data: any) => {
            setOrderId(data.data().orderDocId)
        })
    }, [])

    useEffect(() => {
        getRestaurantDetailStream(resId, (data: any) => {
            setRestaurant(data.data())
            if (data.data().screen) {
                const { textColor, backgroundColor } = data.data().screen
                document.documentElement.style.setProperty('--primary-color', textColor)
                document.documentElement.style.setProperty('--secondary-color', backgroundColor)
                document.documentElement.style.setProperty('--primary-color-50', convertHexToRGBA(textColor, 0.5))
                document.documentElement.style.setProperty('--primary-color-80', convertHexToRGBA(textColor, 0.8))
            } else {
                document.documentElement.style.setProperty('--primary-color', '#8A6025')
                document.documentElement.style.setProperty('--secondary-color', '#fafafb')
                document.documentElement.style.setProperty('--primary-color-50', 'rgba(138, 96, 37, 0.5)')
                document.documentElement.style.setProperty('--primary-color-80', 'rgba(138, 96, 37, 0.8)')
            }
        })
    }, [resId])

    useEffect(() => {
        if (orderId) {
            streamOrderById(resId, orderId, (data: any) => {
                setOrder(data.data())
            })
        } else {
            setOrder(null)
        }
    }, [orderId])

    return <div>{order && orderId ? <OrderScreen data={order} restaurant={restaurant} /> : <Home restaurant={restaurant} />}</div>
}
export default PaymentScreen

interface OrderProps {
    data: any
    restaurant: any
}

interface HomeProps {
    restaurant: any
}

const Home: FC<HomeProps> = ({ restaurant }) => {
    const [index, setIndex] = React.useState(0)
    const timeoutRef = React.useRef(null)

    useEffect(() => {
        if (restaurant?.adsPath.length == 1) return
        resetTimeout()
        // @ts-ignore
        timeoutRef.current = setTimeout(() => setIndex((prevIndex) => (prevIndex === restaurant?.adsPath.length - 1 ? 0 : prevIndex + 1)), 3000)
        return () => {
            resetTimeout()
        }
    }, [index, restaurant?.adsPath])

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
    }

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
            }}
        >
            <div className="slideshowSlider" style={{ height: '100%', transform: `translate3d(${-index * 100}%, 0, 0)` }}>
                {restaurant?.adsPath?.map((image: string, index: number) => (
                    <div
                        className="slide"
                        key={index}
                        style={{
                            backgroundImage: `url('${restaurant?.adsPath[index]}')`,
                            backgroundSize: 'cover',
                            height: '100%',
                        }}
                    />
                ))}
            </div>
        </div>
    )
}

const OrderScreen: FC<OrderProps> = ({ data, restaurant }) => {
    const [total, setTotal] = useState(0)
    const [tax, setTax] = useState(0)
    const [totalPreTax, setTotalPretax] = useState(0)
    const [discount, setDiscount] = useState<any>()
    const language = useSelector((store: RootState) => store.customer.language)

    useEffect(() => {
        let total = 0
        let totalPreTax = 0
        data.productsPaid?.forEach((item: any) => {
            if ((item.options !== undefined && item.options.length > 0) || (item.toppings !== undefined && item.toppings.length > 0)) {
                let priceProduct = item.pricePostTax
                let pricePreTax = item.price

                item.options.forEach((option: any) => {
                    const priceOption = option.type.find((obj: any) => obj.isSelect === true)?.price

                    priceProduct += Number(priceOption)
                    pricePreTax += Math.round(Number(priceOption) / 1.1)
                })

                item.toppings?.forEach((topping: any) => {
                    topping.type.forEach((type: any) => {
                        if (type.quantity > 0) {
                            priceProduct += type.pricePostTax * type.quantity
                            pricePreTax += type.price * type.quantity
                        }
                    })
                })

                total = total + priceProduct * item.quantity
                totalPreTax = totalPreTax + pricePreTax * item.quantity
            } else {
                total = total + item.pricePostTax * item.quantity
                totalPreTax = totalPreTax + item.price * item.quantity
            }
        })
        const _tax = total - totalPreTax
        let discount = 0
        if (data?.voucher?.type === 'VALUE') {
            discount = data.voucher.value
        } else if (data?.voucher?.type === 'PERCENT') {
            discount = (data.voucher.value / 100) * total
        }

        setDiscount(Math.round(discount))

        setTax(_tax)
        setTotal(total - Math.round(discount))
        setTotalPretax(totalPreTax)
    }, [data])

    const generateOptions = (item: any) => {
        const options: any[] = []
        item?.options?.forEach((item: any) => {
            let name = item.name

            item?.type?.forEach((type: any) => {
                if (type.isSelect) {
                    name += `: ${type?.name}`
                }
            })
            options.push(name)
        })

        return options.map((option) => {
            return <p style={{ marginBottom: 0 }}>- {option}</p>
        })
    }

    const generateTopping = (item: any) => {
        const toppings: any[] = []

        item?.toppings?.forEach((topping: any) => {
            topping?.type?.forEach((type: any) => {
                if (type.quantity > 0) {
                    toppings.push(`${type?.name} x ${type.quantity}`)
                }
            })
        })

        return toppings.map((topping) => {
            return <p style={{ marginBottom: 0 }}>- {topping}</p>
        })
    }

    const calcPrive = (item: any, price: number) => {
        let totalPrice = price
        item?.toppings?.forEach((topping: any) => {
            topping.type.forEach((type: any) => {
                if (type.quantity > 0) {
                    totalPrice += type.pricePostTax * type.quantity
                }
            })
        })

        item?.options?.forEach((option: any) => {
            const priceOption = option.type.find((obj: any) => obj.isSelect === true)?.price
            totalPrice += Number(priceOption)
        })

        return numberWithCommas(totalPrice)
    }

    if (data.status === 'inprocess') {
        return (
            <div className="order-screen">
                <div className="order-detail">
                    <Row style={{ height: '100%' }}>
                        <Col span={12} style={{ border: '3px solid var(--primary-color)', borderRadius: 7, padding: 10, height: '100%' }}>
                            <div className="list">
                                {data?.productsPaid?.map((item: any) => (
                                    <Row
                                        gutter={12}
                                        style={{
                                            alignItems: 'center',
                                            marginTop: 10,
                                            padding: '10px 0px',
                                            borderBottom: '1px solid lightgray',
                                        }}
                                    >
                                        <Col span={4}>
                                            <img alt={item.image ?? restaurant.imageDefault} src={item.image ? item.image : restaurant.imageDefault} width={70} />
                                        </Col>

                                        <Col span={10} className="row-detail">
                                            <h4>{item.name}</h4>
                                            {generateOptions(item)}
                                            {generateTopping(item)}
                                        </Col>

                                        <Col span={10}>
                                            <Row style={{ width: '100%', alignItems: 'center' }}>
                                                <Col span={8}>
                                                    <strong>{calcPrive(item, item.pricePostTax * 1)}</strong>
                                                </Col>
                                                <Col span={4} className="box">
                                                    <strong>{item.quantity}</strong>
                                                </Col>
                                                <Col span={8}>
                                                    <strong>{calcPrive(item, item.pricePostTax * item.quantity)}</strong>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </Col>

                        <Col
                            span={12}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '5vh',
                                justifyContent: 'center',
                                maxHeight: '100%',
                            }}
                        >
                            <div>
                                <h2 style={{ fontSize: 55, color: 'var(--primary-color)', fontWeight: 700 }}>{restaurant?.name}</h2>
                                <h3 style={{ fontSize: 45 }}>{data?.table_name}</h3>
                            </div>
                            <Row className="price">
                                <Col span={24}>
                                    <img width={150} src={restaurant?.imageDefault} alt="" />
                                </Col>

                                <Col span={24} style={{ padding: 20 }}>
                                    <table border={2} className="inprocess-table">
                                        <tbody>
                                            <tr>
                                                <td>小計</td>
                                                <td>{numberWithCommas(totalPreTax)}</td>
                                            </tr>
                                            <tr>
                                                <td>税</td>
                                                <td>{numberWithCommas(tax)}</td>
                                            </tr>
                                            {data?.voucher?.type && data?.voucher?.value !== 0 && (
                                                <tr>
                                                    <td>割引</td>
                                                    <td>{numberWithCommas(discount)}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>合計</td>
                                                <td>{numberWithCommas(total)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', height: '100vh', flexDirection: 'column', justifyContent: 'center', padding: 20 }}>
            <h1 style={{ fontSize: 55, color: 'var(--primary-color)', fontWeight: 700 }}>{restaurant.name}</h1>
            <Row className="price">
                <Col span={7}>
                    <img width={200} src={restaurant?.imageDefault} alt="" />
                    <h2 style={{ fontSize: 30 }}>{data.table_name}</h2>
                </Col>

                <Col span={17}>
                    <table border={2} className="done-table">
                        <tbody>
                            <tr>
                                <td>合計</td>
                                <td>{numberWithCommas(total)}</td>
                            </tr>
                            {data?.payment_method.map((item: any) => {
                                return (
                                    <tr>
                                        <td>{item.name}</td>
                                        <td>{numberWithCommas(item.customerPaid)}</td>
                                    </tr>
                                )
                            })}
                            {data?.voucher?.type && data?.voucher?.value !== 0 && (
                                <tr>
                                    <td>割引</td>
                                    <td>{numberWithCommas(discount)}</td>
                                </tr>
                            )}
                            <tr>
                                <td>おつり</td>
                                <td>{numberWithCommas(data.amount_refund ? data.amount_refund : 0)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <h1 style={{ fontSize: 30, letterSpacing: 5, fontWeight: 700, marginTop: 15 }}>ご来店ありがとうございます</h1>

                    <h5 style={{ fontSize: 18 }}>ATPOS 格安セルフオーダーシステム</h5>
                </Col>
            </Row>
        </div>
    )
}
