import React from 'react';
import { FlexProps } from '../../../model/ui-components';

const dataDefault = {
	mobileFlex: true,
	flexDirection: 'row',
	className: 'header-cart-detail'
}

const Flex = (props: FlexProps) => {

	props = { ...dataDefault, ...props }

	const { children, className, alignItems, justifyContent, mobileFlex, flexDirection } = props
	const getFlexResponsive = () => mobileFlex ? 'd-flex' : 'd-md-flex'
	return (
		<div className={`${getFlexResponsive()} ${className} ${flexDirection ? ('flex-' + flexDirection) : ''} ${alignItems ? ('align-items-' + alignItems) : ''} ${justifyContent ? ('justify-content-' + justifyContent) : ''}`}>
			{children}
		</div>
	)
}

export default Flex
