import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface CustomerSetting {
  isCustomer: boolean;
  lastScreen: string;
  language: string;
  isCustomerApp: boolean;
  customerAppId: string;
}

const initialState: CustomerSetting = {
  isCustomerApp: false,
  isCustomer: false,
  lastScreen: "",
  language: "",
  customerAppId: ""
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerApp: (state, action: PayloadAction<boolean>) => {
      return {...state, isCustomerApp: action.payload};
    },

    setCustomerAppId: (state, action: PayloadAction<string>) => {
      return {...state, customerAppId: action.payload};
    },

    setCustomerMode: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isCustomer: action.payload,
      };
    },

    setLastScreen: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        lastScreen: action.payload,
      };
    },

    setLanguage: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        language: action.payload,
      };
    },

    clearCustomerSetting: (state) => {
      return {
        ...initialState,
        isCustomerApp: state.isCustomerApp,
        customerAppId: state.customerAppId
      };
    },
  },
});
export const { clearCustomerSetting, setLastScreen, setCustomerMode,setLanguage,setCustomerApp,setCustomerAppId } = customerSlice.actions;
export default customerSlice.reducer;
