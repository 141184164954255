import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import User from "../model/User";
import { RootState } from "./store";

const initialState: User = {
    uid: "",
    email: "",
    displayName: "",
    photoURL: "",
    is_login: false,
    is_active: false,
    permission: 0,
    id_restaurant: "",
    restaurant_name: ""
};

export const userSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        setUserLogin: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                uid: action.payload.uid,
                email: action.payload.email,
                displayName: action.payload.displayName,
                photoURL: action.payload.photoURL,
                is_login: true,
                permission: action.payload.permission,
                id_restaurant: action.payload.permission === 0 ? "" : action.payload.id_restaurant,
                ref_restaurant: action.payload.ref_restaurant,
                restaurant_name: action.payload.restaurant_name
            }
        },
        setUserLogout: state => {
            return {
                ...initialState
            }
        },
    }
})

export const selectuser = (rootState: RootState) => rootState.user;
export const selectUserIsLogin = (rootState: RootState) => selectuser(rootState).is_login;
export const { setUserLogin, setUserLogout } = userSlice.actions;
export default userSlice.reducer;