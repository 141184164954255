import { db } from '../configs/FireBaseConf'

import { collection, doc, getDoc, getDocs, increment, limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp, updateDoc, where, startAt, startAfter } from 'firebase/firestore'
import Order from '../model/Order'
import { INPUT_CODE_LIMITATION } from '../constants/index'

export const getUserDetail = async (uuid: string) => {
    const docRef = doc(db, 'user', uuid)
    return getDoc(docRef)
}

export const getRestaurantDetail = async (uuid: string) => {
    const docRef = doc(db, 'restaurant', uuid)
    return getDoc(docRef)
}

export const getRestaurantDetailByCustomerAppId = async (customerAppId: string) => {
    const q = query(
        collection(db, 'restaurant'),
        where('customer_app_id', '==', customerAppId),
        limit(1) // Limit the query to one result
    )
    const querySnapshot = await getDocs(q)

    if (!querySnapshot.empty) {
        // Check if there are matching documents
        const doc = querySnapshot.docs[0] // Get the first (and only) document
        return { ...doc.data(), id: doc.id } // Return the data of the document
    } else {
        return null // No matching document found
    }
}

export const getRestaurantDetailByDeviceId = async (deviceId: string, active?: boolean) => {
    const queryObject: any = {
        code: deviceId,
    }

    if (active) {
        queryObject.active = true
    } else {
        queryObject.active = false
    }

    const q = query(
        collection(db, 'restaurant'),
        where('devices', 'array-contains', queryObject),
        limit(1) // Limit the query to one result
    )
    const querySnapshot = await getDocs(q)

    if (!querySnapshot.empty) {
        // Check if there are matching documents
        const doc = querySnapshot.docs[0] // Get the first (and only) document
        return { ...doc.data(), id: doc.id } // Return the data of the document
    } else {
        return null // No matching document found
    }
}

export const streamOrderDetail = async (resId: string, shiftId: string, callback: (data: any) => void) => {
    const q = query(collection(db, 'restaurant', resId, 'detail'), where('shiftId', '==', shiftId), orderBy('createdAt'))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
            let dataList: any[] = []
            const list: any[] = []
            // Check if there are matching documents
            const doc = querySnapshot.docs[0] // Get the first (and only) document
            dataList = querySnapshot.docs.map((item, index) => {
                return {
                    ...item.data(),
                    id: item.id,
                    index: index,
                }
            })
            dataList.forEach((item) => {
                item.detail.forEach((detail: any, index: number) => {
                    list.push({
                        ...detail,
                        order: item,
                        index: index,
                        id: detail.id + '-' + detail?.timeAddToCart?.seconds,
                    })
                })
            })
            callback(list)
        } else {
            console.log('no')
            callback([])
        }
    })

    // Return the unsubscribe function to stop listening to changes
    return unsubscribe
}

export const updateOrderDetailStatus = (resId: string, id: string, detail: any[]) => {
    const orderDetailRef = doc(db, 'restaurant', resId, 'detail', id)
    return updateDoc(orderDetailRef, {
        detail,
    })
}

export const streamRestaurantDetailByDeviceId = async (deviceId: string, callback: (data: any) => void, active?: boolean) => {
    console.log(deviceId)

    const queryObject: any = {
        code: deviceId,
    }

    if (active) {
        queryObject.active = true
    } else {
        queryObject.active = false
    }

    const q = query(
        collection(db, 'restaurant'),
        where('devices', 'array-contains', queryObject),
        limit(1) // Limit the query to one result
    )
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        console.log('aaaa')

        if (!querySnapshot.empty) {
            // Check if there are matching documents
            const doc = querySnapshot.docs[0] // Get the first (and only) document
            callback(doc) // Callback with the document snapshot
        } else {
            callback(null) // No matching document found
        }
    })

    // Return the unsubscribe function to stop listening to changes
    return unsubscribe
}

export const updateRestaurantDevicesId = (id: string, devices: any[]) => {
    const restaurantRef = doc(db, 'restaurant', id)
    return updateDoc(restaurantRef, {
        devices,
    })
}

export const streamPrinterConfig = async (uuid: string, type: string, callback: (data: any) => void) => {
    const docRef = query(collection(db, 'restaurant', uuid, 'printer'), where('name', '==', type))
    return onSnapshot(docRef, callback)
}

export const getRestaurantDetailStream = async (uuid: string, callback: (data: any) => void) => {
    const docRef = doc(db, 'restaurant', uuid)
    return onSnapshot(docRef, callback)
}

export const getRestaurantTableById = async (resId: string, tableId: string) => {
    const docRef = doc(db, 'restaurant', resId, 'table', tableId)
    return getDoc(docRef)
}

export const getTablesList = (idRes: string, condination: any = null, snapshot: any, error: any) => {
    const tableRef = collection(db, 'restaurant', idRes, 'table')
    const q = query(tableRef, where('is_active', '==', true), orderBy('is_used', 'desc'), orderBy('created', 'asc'))

    return onSnapshot(q, snapshot, error)
}

export const getCateById = async (resId: string, cateId: string) => {
    const docRef = doc(db, 'restaurant', resId, 'category', cateId)
    return getDoc(docRef)
}

export const getParentCate = async (resId: string, isStaff: boolean = false, snapshot: any, error: any) => {
    let q
    if (isStaff) {
        q = query(collection(db, 'restaurant', resId, 'category'), where('parent_id', '==', ''), orderBy('order', 'asc'))
    } else {
        q = query(collection(db, 'restaurant', resId, 'category'), where('is_active', '==', true), where('parent_id', '==', ''), orderBy('order', 'asc'))
    }
    return onSnapshot(q, snapshot, error)
}

export const getChildCate = async (resId: string, condination: any = null, snapshot: any, error: any, isStaff: boolean = false) => {
    let q

    if (isStaff) {
        q = query(collection(db, 'restaurant', resId, 'category'), where('parent_id', '==', condination), orderBy('order', 'asc'))
    } else {
        q = query(collection(db, 'restaurant', resId, 'category'), where('is_active', '==', true), where('parent_id', '==', condination), orderBy('order', 'asc'))
    }

    return onSnapshot(q, snapshot, error)
}

export const getSearchFood = async (resId: string, value: string, snapshot: any, error: any, hasBuffet?: boolean) => {
    if (!resId || !value) return

    let queryString
    const itemsColRef = collection(db, 'restaurant', resId, 'filter_food_code')

    // Throttle the request research only work if value = 3
    if (value.toString().length < INPUT_CODE_LIMITATION) return

    if (hasBuffet) {
        queryString = query(itemsColRef, orderBy('food_code'), where('food_code', 'array-contains', value))

        return onSnapshot(
            queryString,
            async (querySnapshot: any) => {
                const returnData = await Promise.all(
                    querySnapshot.docs.map(async (item: any) => {
                        const docRef = doc(db, 'restaurant', resId, 'food', item.data().id_food)
                        const foodDocSnap = await getDoc(docRef)

                        if (foodDocSnap.exists()) {
                            // summary options
                            if (foodDocSnap.data().options.length > 0) {
                                var options = []
                                for (let option of foodDocSnap.data().options) {
                                    const docRef = doc(db, 'restaurant', resId, 'options', option.value)
                                    const docSnap = await getDoc(docRef)
                                    if (docSnap.exists()) {
                                        options.push({ id: docSnap.id, ...docSnap.data() })
                                    }
                                }
                            }

                            // summary toppings
                            if (foodDocSnap.data().toppings?.length > 0) {
                                var topping = []
                                for (const toppingItem of foodDocSnap.data().toppings) {
                                    const docRef = doc(db, 'restaurant', resId, 'toppings', toppingItem.value)
                                    const docSnap = await getDoc(docRef)
                                    if (docSnap.exists()) {
                                        topping.push({ id: docSnap.id, ...docSnap.data() })
                                    }
                                }
                            }

                            return {
                                id: foodDocSnap.id,
                                code: item?.data()?.food_code[item?.data()?.food_code?.length - 1],
                                ...foodDocSnap?.data(),
                                options: options,
                                toppings: topping,
                            }
                        }
                    })
                )

                snapshot({
                    data: returnData,
                })
            },
            error
        )
    } else {
        queryString = query(itemsColRef, orderBy('food_code'), where('is_buffet', '==', false), where('food_code', 'array-contains', value))

        return onSnapshot(
            queryString,
            async (querySnapshot: any) => {
                const returnData = await Promise.all(
                    querySnapshot.docs.map(async (item: any) => {
                        const docRef = doc(db, 'restaurant', resId, 'food', item.data().id_food)
                        const foodDocSnap = await getDoc(docRef)

                        if (foodDocSnap.exists()) {
                            // summary options
                            if (foodDocSnap.data().options.length > 0) {
                                var options = []
                                for (let option of foodDocSnap.data().options) {
                                    const docRef = doc(db, 'restaurant', resId, 'options', option.value)
                                    const docSnap = await getDoc(docRef)
                                    if (docSnap.exists()) {
                                        options.push({ id: docSnap.id, ...docSnap.data() })
                                    }
                                }
                            }

                            // summary toppings
                            if (foodDocSnap.data().toppings?.length > 0) {
                                var topping = []
                                for (const toppingItem of foodDocSnap.data().toppings) {
                                    const docRef = doc(db, 'restaurant', resId, 'toppings', toppingItem.value)
                                    const docSnap = await getDoc(docRef)
                                    if (docSnap.exists()) {
                                        topping.push({ id: docSnap.id, ...docSnap.data() })
                                    }
                                }
                            }

                            return {
                                id: foodDocSnap.id,
                                code: item?.data()?.food_code[item?.data()?.food_code?.length - 1],
                                ...foodDocSnap?.data(),
                                options: options,
                                toppings: topping,
                            }
                        }
                    })
                )

                snapshot({
                    data: returnData,
                })
            },
            error
        )
    }
}

export const streamFoodListItemsByCategory = async (idRes: string, arrCateId: { value: string }[], snapshot: any, error: any) => {
    const itemsColRef = collection(db, 'restaurant', idRes, 'food')
    const itemsQuery = query(itemsColRef, where('category_id', 'array-contains-any', arrCateId), orderBy('created'))
    return onSnapshot(
        itemsQuery,
        async (querySnapshot: any) => {
            const returnData = await Promise.all(
                querySnapshot.docs.map(async (item: any) => {
                    if (item.data().options.length > 0) {
                        var options = []
                        for (var i = 0; i < item.data().options.length; i++) {
                            const docRef = doc(db, 'restaurant', idRes, 'options', item.data().options[i].value)
                            const docSnap = await getDoc(docRef)
                            if (docSnap.exists()) {
                                if (docSnap?.data()?.is_active) {
                                    options.push({ id: docSnap.id, ...docSnap.data() })
                                }
                            }
                        }
                    }

                    if (item.data().toppings?.length > 0) {
                        var topping = []
                        for (var i = 0; i < item.data().toppings.length; i++) {
                            const docRef = doc(db, 'restaurant', idRes, 'toppings', item.data().toppings[i].value)
                            const docSnap = await getDoc(docRef)
                            if (docSnap.exists()) {
                                if (docSnap?.data()?.is_active) {
                                    topping.push({ id: docSnap.id, ...docSnap.data() })
                                }
                            }
                        }
                    }

                    return {
                        ...item.data(),
                        id: item.id,
                        options: options,
                        toppings: topping,
                    }
                })
            )
            snapshot(returnData)
        },
        error
    )
}

export const getFoodListItemsByCategory = async (idRes: string, arrCateId: { value: string }[]) => {
    const itemsColRef = collection(db, 'restaurant', idRes, 'food')
    const itemsQuery = query(itemsColRef, where('category_id', 'array-contains-any', arrCateId), orderBy('created'))
    const data = await getDocs(itemsQuery)

    const returnData = await Promise.all(
        data.docs.map(async (item: any) => {
            if (item.data().options.length > 0) {
                var options = []
                for (var i = 0; i < item.data().options.length; i++) {
                    const docRef = doc(db, 'restaurant', idRes, 'options', item.data().options[i].value)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        options.push({ id: docSnap.id, ...docSnap.data() })
                    }
                }
            }

            return { ...item.data(), id: item.id, options: options }
        })
    )
    return returnData
}

export const getTableDetail = async (idRes: string, idTable: string, snapshot: any, error: any) => {
    return onSnapshot(doc(db, 'restaurant', idRes, 'table', idTable), snapshot, error)
}

export const handleGetOrderId = async (idRes: string) => {
    const settingDocRef = doc(db, 'restaurant', idRes, 'setting', idRes)

    try {
        const transactionResult = await runTransaction(db, async (transaction) => {
            const sfDoc = await transaction.get(settingDocRef)
            if (sfDoc.exists()) {
                transaction.update(settingDocRef, { newOrderId: increment(1) })

                if (sfDoc?.data().newOrderId) {
                    const new_id = parseFloat(sfDoc?.data().newOrderId) + 1
                    return String(new_id).padStart(6, '0')
                } else {
                    return '000001'
                }
            }
        })
        return transactionResult
    } catch (e) {
        console.error(e)
    }
}

export const createOrder = async (idRes: string, idTable: string, newOrderData: Order, updateTableAfterNewData: any, orderId: any, shiftId: any) => {
    const orderRef = doc(collection(db, 'restaurant', idRes, 'order'))
    const detailRef = doc(collection(db, 'restaurant', idRes, 'detail'))
    const tableRef = doc(db, 'restaurant', idRes, 'table', idTable)

    try {
        return await runTransaction(db, async (transaction) => {
            const tableItem = await transaction.get(tableRef)
            if (tableItem.exists()) {
                if (tableItem.data().is_used === true) {
                    return false
                }

                if (orderId) {
                    transaction.set(orderRef, {
                        ...newOrderData,
                        order_id: orderId,
                        createdAt: serverTimestamp(),
                        updatedAt: serverTimestamp(),
                    })
                    transaction.update(tableRef, {
                        ...updateTableAfterNewData,
                        is_used: true,
                        order_id: orderId,
                        oredr_docID: orderRef.id,
                        updatedAt: serverTimestamp(),
                    })

                    transaction.set(detailRef, {
                        order_id: orderId,
                        oredr_docID: orderRef.id,
                        detail: newOrderData.detail,
                        number_people: newOrderData.number_people,
                        isPrint: true,
                        table_name: newOrderData.table_name,
                        total_amount: newOrderData.total_amount,
                        type: 'web',
                        ver: '20240711',
                        shiftId: shiftId,
                        createdAt: serverTimestamp(),
                        updatedAt: new Date().toLocaleTimeString(navigator.language, {
                            hour: '2-digit',
                            minute: '2-digit',
                        }),
                    })
                } else {
                    transaction.set(orderRef, {
                        ...newOrderData,
                        order_id: '000001',
                        createdAt: serverTimestamp(),
                        updatedAt: serverTimestamp(),
                    })
                }
            }
            return true
        })
    } catch (error) {
        console.log('Create transaction order got failed.', error)
        return false
    }
}

export const changeQuantityFoodOrder = async (idRes: string, idOrder: string, idTable: string, OrderList: Order, updateOrderData: any, updateTableAfterUpdateData: any, newDetail: any) => {
    const orderRef = doc(db, 'restaurant', idRes, 'order', idOrder)
    const tableRef = doc(db, 'restaurant', idRes, 'table', idTable)
    const detailRef = doc(collection(db, 'restaurant', idRes, 'detail'))
    try {
        return await runTransaction(db, async (transaction) => {
            const orderItem = await transaction.get(orderRef)
            if (orderItem.exists()) {
                if (OrderList.countUpdate !== orderItem.data().countUpdate) {
                    return false
                }
                transaction.update(orderRef, {
                    ...updateOrderData,
                    updatedAt: serverTimestamp(),
                })

                transaction.update(tableRef, {
                    ...updateTableAfterUpdateData,
                })

                transaction.set(detailRef, {
                    ...newDetail,
                    type: 'web',
                    ver: '20240711',
                    createdAt: serverTimestamp(),
                })
            }
            return true
        })
    } catch (error) {
        console.log('changed food order got failed.', error)
        return false
    }
}

export const updateOrder = async (idRes: string, idOrder: string, idTable: string, newDetail: any, updateOrderData: Order, updateTableAfterUpdateData: any, OrderList: Order) => {
    const orderRef = doc(db, 'restaurant', idRes, 'order', idOrder)
    const tableRef = doc(db, 'restaurant', idRes, 'table', idTable)
    const detailRef = doc(collection(db, 'restaurant', idRes, 'detail'))

    try {
        return await runTransaction(db, async (transaction) => {
            const orderItem = await transaction.get(orderRef)
            if (orderItem.exists()) {
                if (OrderList.countUpdate !== orderItem.data().countUpdate) {
                    return false
                }
                transaction.update(orderRef, {
                    ...updateOrderData,
                    updatedAt: serverTimestamp(),
                })

                transaction.update(tableRef, {
                    ...updateTableAfterUpdateData,
                })

                transaction.set(detailRef, {
                    ...newDetail,
                    type: 'web',
                    ver: '20240711',
                    createdAt: serverTimestamp(),
                })
            }
            return true
        })
    } catch (error) {
        console.log('Update transaction order got failed.', error)
        return false
    }
}

export const getOrderListHistory = (idRes: string, idOrder: string, snapshot: any, error: any) => {
    return onSnapshot(doc(db, 'restaurant', idRes, 'order', idOrder), snapshot, error)
}

export const getDetailList = (idRes: string, idOrder: string, snapshot: any, error: any) => {
    const detailRef = collection(db, 'restaurant', idRes, 'detail')
    const q = query(detailRef, where('order_id', '==', idOrder))
    return onSnapshot(q, snapshot, error)
}

export const getOrderDetail = async (resId: string, idOrder: string) => {
    const docRef = doc(db, 'restaurant', resId, 'order', idOrder)
    const data = await getDoc(docRef)
    if (data.exists()) return data.data()
    else return null
}

export const getOrderListHistoryInProcess = (idRes: string, opentime: any, snapshot: any, error: any) => {
    const ordersRef = collection(db, 'restaurant', idRes, 'order')
    const queryRef = query(ordersRef, where('status', '==', 'inprocess'), where('updatedAt', '<', opentime))
    return onSnapshot(queryRef, snapshot, error)
}

export const getOrderListHistoryByShift = (idRes: string, shiftId: string, snapshot: any, error: any) => {
    const ordersRef = collection(db, 'restaurant', idRes, 'detail')
    const queryRef = query(ordersRef, where('shiftId', '==', shiftId))
    return onSnapshot(queryRef, snapshot, error)
}
export const getShiftOpening = async (resId: string, condination: any = null, snapshot: any, error: any) => {
    const q = query(collection(db, 'restaurant', resId, 'shift'), where('close_time', '==', ''))
    return onSnapshot(q, snapshot, error)
}

export const callServiceTable = (idRes: string, idTable: string, data: { notify?: boolean; isPaid?: boolean }) => {
    const tableRef = doc(db, 'restaurant', idRes, 'table', idTable)
    return updateDoc(tableRef, {
        ...data,
    })
}

export const getStaffListItems = (idRes: string, condination: any = null, snapshot: any, error: any) => {
    const itemsColRef = collection(db, 'restaurant', idRes, 'staff')
    const itemsQuery = query(itemsColRef, orderBy('created', 'asc'))
    return onSnapshot(itemsQuery, snapshot, error)
}

export const getDefaultStaffCode = async (idRes: string) => {
    const staffsQuery = query(collection(db, 'restaurant', idRes, 'staff'))
    const data = await getDocs(staffsQuery)
    return data.size > 0 ? data.docs[0].id : null
}

export const getStaff = async (idRes: string, staffId: string) => {
    const docRef = doc(db, 'restaurant', idRes, 'staff', staffId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return null
    }
}

export const streamPaymentScreen = (resId: string, callback: any) => {
    const docRef = doc(db, 'restaurant', resId, 'paymentScreen', '1')
    return onSnapshot(docRef, callback)
}

export const streamOrderById = (resId: string, orderId: string, callback: any) => {
    const docRef = doc(db, 'restaurant', resId, 'order', orderId)
    return onSnapshot(docRef, callback)
}
