import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from "../constants/ThemeConstant";
import Theme from "../model/Theme";
import { RootState } from "./store";

export const initialState: Theme = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: "en",
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: "",
    mobileNav: false,
    currentTheme: "light",
    direction: DIR_LTR
};

export const themeSlice = createSlice({
    name: 'themeReducer',
    initialState,
    reducers: {
        toggleCollapsedNav: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                navCollapsed: action.payload
            }
        }, onMobileNavToggle: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                mobileNav: action.payload
            }
        }, onLocaleChange: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                locale: action.payload
            }
        }
    }
})

export const selectTheme = (rootState: RootState) => rootState.theme;
export const { toggleCollapsedNav, onMobileNavToggle, onLocaleChange } = themeSlice.actions;
export default themeSlice.reducer;