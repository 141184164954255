import { auth } from "../configs/FireBaseConf";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getRestaurantDetail, getUserDetail } from "./FirebaseFirestoreService";

export const loginUser = async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password);
}

export const getProfile = async () => {
    const user = auth.currentUser;
    if (user !== null) {
        const userDetail = await getUserDetail(user.uid);
        if (userDetail.exists()) {
            const restDetail = await getRestaurantDetail(userDetail.data().id_restaurant);
            if (restDetail.exists()) {
                return {
                    "displayName": user.displayName,
                    "email": user.email,
                    "photoURL": restDetail.data().imageDefault,
                    "uid": user.uid,
                    "permission": userDetail.data().permission,
                    "id_restaurant": userDetail.data().id_restaurant,
                    "restaurant_name": restDetail.data().name,
                    "is_active": userDetail.data().is_active
                }
            }
            throw new Error('Cannot load Restaurant profile')
        }
        throw new Error('Cannot load User profile')
    }
    throw new Error('Username/password is wrong')
}

export const logoutUser = async () => {
    return await signOut(auth);
}

export const authChangeState = (handler: any) => {
    return onAuthStateChanged(auth, (user) => {
        handler(user);
    });
}