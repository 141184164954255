const kitchenTranslation: any = {
  vn: {
    logo: "Danh sách đặt hàng",
    new: "Đơn hàng mới",
    preparing: "Đang làm",
    pending: "Đã xong",
    finished: 'Hoàn thành'
  },
  jp: {
    logo: "注文リスト",
    new: "新しい注文",
    preparing: "調理中",
    pending: "調理湇み",
    finished: '履歴'
  },
};

export default kitchenTranslation;
