export const rules: any = {
  numberFormatRequire: [
    {
      required: true,
      message: "Please enter this field",
    },
    () => ({
      validator(_: any, value: any) {
        if (isNaN(value)) {
          return Promise.reject("Please enter number");
        }
        return Promise.resolve();
      },
    }),
  ],
}

export const INPUT_CODE_LIMITATION = 3