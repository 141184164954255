import enLang from './entries/en_US';
import viLang from './entries/vi_VN';
import jaLang from './entries/ja_JP';


const AppLocale: any = {
    en: enLang,
    vi: viLang,
    ja: jaLang
};

export default AppLocale;