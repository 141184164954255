import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

import AppLocale from "../lang";
import AuthLayout from "../layouts/auth-layout";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, CUSTOMER_PREFIX_PATH } from "../configs/AppConfig";
import AuthRoute from "./auth-views/components/AuthRoute";
import { selectTheme } from "../redux/themeSlice";
import AppLayout from "../layouts/app-layout";
import CustomerIframe from "./app-views/tables/customer-iframe";
import PaymentScreen from "./app-views/payment-screen/payment-screen";
import { KitchenApp } from "./app-views/kitchen-app";
import PaymentScreenMobile from "./app-views/payment-screen/payment-screen-mobile";

export const Views = (props: any) => {
  const { navCollapsed, navType, headerNavColor, currentTheme, mobileNav, locale, topNavColor, sideNavTheme, direction } = useSelector(selectTheme);

  const currentAppLocale = AppLocale[locale];
  const { location } = props;

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
        <Route path={'/customer-iframe'} component={CustomerIframe}/>
        <Route path={'/payment-screen/:resId'} component={PaymentScreen}/>
        <Route path={'/payment-screen-mobile/:resId'} component={PaymentScreenMobile}/>
        <Route path={'/kitchen-screen/:resId'} component={KitchenApp}/>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout />
          </Route>
          <AuthRoute path={APP_PREFIX_PATH}>
            <AppLayout
              location={location}
              navCollapsed={navCollapsed}
              sideNavTheme={sideNavTheme}
              locale={locale}
              navType={navType}
              topNavColor={topNavColor}
              headerNavColor={headerNavColor}
              mobileNav={mobileNav}
              currentTheme={currentTheme}
              direction={direction}
            />
          </AuthRoute>
          <Route path={CUSTOMER_PREFIX_PATH}>
            <AppLayout
              location={location}
              navCollapsed={navCollapsed}
              sideNavTheme={sideNavTheme}
              locale={locale}
              navType={navType}
              topNavColor={topNavColor}
              headerNavColor={headerNavColor}
              mobileNav={mobileNav}
              currentTheme={currentTheme}
              direction={direction}
            />
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

export default Views;
