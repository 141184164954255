import React, { useContext, useEffect, useRef, useState } from 'react'
import './index.css'
import { useParams, useHistory } from 'react-router-dom'
import { getRestaurantDetail, getShiftOpening, streamOrderDetail, streamPrinterConfig, updateOrderDetailStatus } from '../../../services/FirebaseFirestoreService'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { differenceInMinutes, formatDistance } from 'date-fns'
import { ja, vi } from 'date-fns/locale'
import { HistoryOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Modal, ModalProps, Row, Select } from 'antd'
import { selectuser, setUserLogout } from '../../../redux/userSlice'
import User from '../../../model/User'
import { logoutUser } from '../../../services/FirebaseAuthService'
import { RootState, useAppDispatch } from '../../../redux/store'
import { RestaurantContext } from '../../../context/restaurantContext'
import { setLanguage } from '../../../redux/customerSlice'
import { current } from '@reduxjs/toolkit'
import { Dropdown } from 'semantic-ui-react'
import kitchenTranslation from './translate'
import { Howl, Howler } from 'howler'
import { convertToDateTime } from '../../../helper/utils'

const sound2 = new Howl({
    src: ['/sound/khi-chyen-tab.mp3'],
})

export const KitchenApp = () => {
    // const { resId }: any = useParams();
    const [currentShift, setCurrentShift] = useState<any>()
    const [orders, setOrders] = useState([])
    const orderRef = useRef<any>([])
    const [open, setOpen] = useState(false)
    const currentUser: User = useSelector(selectuser)
    const resId = currentUser.id_restaurant
    const [restaurant, setRestaurant] = useState<any>()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const soundRef = useRef<any>()
    const soundRefNew = useRef<any>()
    const [allowNotification, setAllowNotification] = useState(true)
    const allow = !Boolean(localStorage.getItem('allowNotificationModal'))
    const [allowNotificationModal, setAllowNotificationModal] = useState(true)
    const [language, setLanguage] = useState('vn')
    const [map, setMap] = useState<any>()
    const [type, setType] = useState<string>('食物')
    const isNoti = useRef<any[]>([])
    const fetchRestaurant = async () => {
        const res = await getRestaurantDetail(currentUser.id_restaurant)
        setRestaurant(res.data())
    }

    const sound = new Howl({
        src: ['/sound/new.mp3'],
    })

    useEffect(() => {
        if (restaurant) {
            streamPrinterConfig(resId, type, (data) => {
                if (data.docs[0]) {
                    const stringMap = new Map()
                    data.docs[0].data().listCategory.forEach((category: string) => {
                        stringMap.set(category, true)
                    })
                    setMap(stringMap)
                }
            })
        }
    }, [restaurant, type])

    const checkExistCategory = (categories: string[]): boolean => {
        for (const category of categories) {
            if (map.has(category)) {
                return true // At least one category exists in the map
            }
        }
        return false // None of the categories exist in the map
    }

    useEffect(() => {
        fetchRestaurant()
    }, [currentUser])

    useEffect(() => {
        if (!currentUser.uid) {
            history.push('/auth/login?app=kitchen')
        }
    }, [currentUser])

    const handleStreamOrderDetail = (data: any) => {
        // if (isNoti.current.length === 0 || isNoti.current.length !== data.length) {
        //   console.log("diff");

        const newList = data.sort((item1: any, item2: any) => {
            const lastStatus1: Date | null = item1.status?.[item1.status.length - 1]?.lastUpdated ? new Date(item1.status[item1.status.length - 1].lastUpdated) : null
            const lastStatus2: Date | null = item2.status?.[item2.status.length - 1]?.lastUpdated ? new Date(item2.status[item2.status.length - 1].lastUpdated) : null

            if (lastStatus1 && lastStatus2) {
                return lastStatus1.getTime() - lastStatus2.getTime()
            } else if (lastStatus1 && !lastStatus2) {
                return -1
            } else if (!lastStatus1 && lastStatus2) {
                return 1
            } else {
                return 0
            }
        })
        let idsArr1 = orderRef.current?.map((obj: any) => obj.id)
        if (!idsArr1) idsArr1 = []
        const missingObjects = newList.filter((obj: any) => !idsArr1.includes(obj.id))

        if (newList.length !== orderRef.current.length) {
            if (missingObjects.length > 0) {
                sound.play()
            } else {
                // soundRef.current.play();
                // sound2.play();
            }
            orderRef.current = newList
        } else {
            // sound2.play();
        }

        setOrders(newList.filter((item: any) => checkExistCategory([item.categoryPrint])))
        isNoti.current = orderRef.current
        // }
    }

    useEffect(() => {
        if (map) {
            getShiftOpening(
                resId,
                null,
                (doc: any) => {
                    if (doc.docs.length === 0) {
                        setCurrentShift(null)
                        return
                    }
                    if (doc.docs[0] && doc.docs[0].data()) {
                        const data = {
                            ...doc.docs[0].data(),
                            id: doc.docs[0].id,
                        }
                        if (currentShift?.id !== data?.id) {
                            setCurrentShift(data)
                        }
                    }
                    //   setCurrentShift(doc.docs[0].data());
                },
                null
            )
            if (currentShift) {
                console.log('sub new')

                const unsub = streamOrderDetail(resId, currentShift.id, handleStreamOrderDetail)
            } else {
                setOrders([])
                orderRef.current = []
            }
        }
        // return unsub;
    }, [currentShift, map])

    const ModalBuffetProps: ModalProps = {
        closeIcon: ' ',
        visible: open,
        className: 'history-modal',
        title: (
            <h1
                style={{
                    textAlign: 'center',
                }}
            >
                {kitchenTranslation[language].finished}
            </h1>
        ),
        onCancel: () => {
            setOpen(false)
        },
        footer: [<></>],
    }

    const ModalNotificationProps: ModalProps = {
        closeIcon: ' ',
        visible: allowNotificationModal,
        className: 'notification-modal',
        title: (
            <h1
                style={{
                    textAlign: 'center',
                }}
            >
                ご案内
            </h1>
        ),
        onCancel: () => {
            setAllowNotificationModal(false)
        },
        footer: [
            <Row
                justify="end"
                style={{
                    width: '100%',
                }}
            >
                <Button
                    type="primary"
                    onClick={() => {
                        setAllowNotificationModal(false)
                        setAllowNotification(true)
                        sound.play()
                        sound2.play()
                        localStorage.setItem('allowNotificationModal', 'true')
                    }}
                >
                    OK
                </Button>
            </Row>,
        ],
    }

    return (
        <div className="kitchen">
            <Modal {...ModalNotificationProps}>
                <p>通知音が鳴ります</p>
            </Modal>
            <audio autoPlay ref={soundRef}>
                <source src="/sound/khi-chyen-tab.mp3" type="audio/mp3" />
                Your browser does not support the audio element.
            </audio>
            <audio autoPlay ref={soundRefNew}>
                <source src="/sound/new.mp3" type="audio/mp3" />
                Your browser does not support the audio element.
            </audio>
            <div className="kitchen__header">
                <div className="logo">{kitchenTranslation[language].logo}</div>
                <div
                    className="select"
                    onChange={(e: any) => {
                        setType(e.target.value)
                    }}
                >
                    <select name="" id="">
                        <option value="食物">In Bếp</option>
                        <option value="飲み物">In nước</option>
                    </select>
                </div>
                <div className="setting">
                    <div className="language-button">
                        <Dropdown
                            onChange={(e: any, data: any) => {
                                setLanguage(data.value)
                            }}
                            placeholder="Select Country"
                            button
                            className="icon dropdown-language"
                            floating
                            fluid
                            icon={null}
                            value={language}
                            options={[
                                {
                                    key: 'vn',
                                    value: 'vn',
                                    flag: 'vn',
                                },
                                {
                                    key: 'jp',
                                    value: 'jp',
                                    flag: 'jp',
                                },
                            ]}
                        />
                    </div>
                    <div className="avatar">
                        <span>{currentUser.displayName}</span>
                        <Avatar src={currentUser.photoURL} size={30} />
                    </div>
                    <button
                        onClick={() => {
                            dispatch(setUserLogout())
                            logoutUser()
                        }}
                    >
                        Logout
                    </button>
                </div>
            </div>
            <div className="kitchen__body">
                <div className="title">
                    <span>{kitchenTranslation[language].new}</span>
                    <span>{kitchenTranslation[language].preparing}</span>
                    <span>{kitchenTranslation[language].pending}</span>
                </div>
                <div className="content">
                    <div className="content__item">
                        {orders
                            .filter((item: any) => !item.status)
                            .map((order) => (
                                <OrderItem resId={resId} data={order} language={language} />
                            ))}
                    </div>
                    <div className="content__item">
                        {orders
                            .filter((item: any) => {
                                if (item.status && item.status[item.status.length - 1].status === 'preparing') return true
                                return false
                            })
                            .map((order) => (
                                <OrderItem resId={resId} data={order} language={language} />
                            ))}
                    </div>
                    <div className="content__item">
                        {orders
                            .filter((item: any) => {
                                if (item.status && item.status[item.status.length - 1].status === 'pending') return true
                                return false
                            })
                            .map((order) => (
                                <OrderItem resId={resId} data={order} language={language} />
                            ))}
                    </div>
                </div>
            </div>
            <div
                className="history"
                onClick={() => {
                    setOpen(true)
                }}
            >
                <HistoryOutlined
                    style={{
                        fontSize: 40,
                        color: '#fff',
                    }}
                />
            </div>

            <Modal {...ModalBuffetProps}>
                {orders
                    .filter((item: any) => {
                        if (item.status && item.status[item.status.length - 1].status === 'finished') return true
                        return false
                    })
                    .map((order) => (
                        <OrderItem resId={resId} data={order} />
                    ))}
            </Modal>
        </div>
    )
}

const OrderItem = ({ data, language, resId }: any) => {
    const [reloadCount, setReloadCount] = useState(0)
    const [lastStatus, setLastStatus] = useState<any>(null)
    const [lastStatusTime, setLastStatusTime] = useState<any>(null)
    const [diffTime, setDiffTime] = useState(0)

    useEffect(() => {
        if (data.order.detail[data.index].status) {
            setLastStatus(data.order.detail[data.index].status[data.order.detail[data.index].status.length - 1].status)

            setLastStatusTime(data.order.detail[data.index].status[data.order.detail[data.index].status.length - 1].lastUpdated)
        }
    }, [data])

    useEffect(() => {
        if (lastStatus) {
            const lastStatusTime = data.order.detail[data.index].status[data.order.detail[data.index].status.length - 1].lastUpdated
            const time = new Date(lastStatusTime)
            const diffMinutes = differenceInMinutes(new Date(), time)
            setDiffTime(diffMinutes)
        }
    }, [lastStatus, reloadCount])

    const generateOptions = (item: any) => {
        const options: any[] = []
        item?.options?.forEach((item: any) => {
            let name = item.name
            item?.type?.forEach((type: any) => {
                if (type.isSelect) {
                    name += `: ${language && type?.nameLanguages && type?.nameLanguages[language] ? type?.nameLanguages[language].name : type?.name}`
                }
            })
            options.push(name)
        })

        return options.map((option) => {
            return <span>- {option}</span>
        })
    }

    const generateTopping = (item: any) => {
        const toppings: any[] = []

        item?.toppings?.forEach((topping: any) => {
            topping?.type?.forEach((type: any) => {
                if (type.quantity > 0) {
                    toppings.push(`${language && type?.nameLanguages && type?.nameLanguages[language] ? type?.nameLanguages[language].name : type?.name} x ${type.quantity}`)
                }
            })
        })

        return toppings.map((topping) => {
            return <span>- {topping}</span>
        })
    }

    const handleChangeStatus = async () => {
        const nextStatus: any = {
            '': 'preparing',
            preparing: 'pending',
            pending: 'finished',
            finished: '',
        }
        const order = data.order
        if (!order?.detail?.[data.index]?.status) {
            order.detail[data.index].status = []
        }
        const lastStatus = order.detail[data.index].status[order.detail[data.index].status.length - 1]?.status

        if (lastStatus === 'finished') {
            return
        }
        order.detail[data.index].status.push({
            status: nextStatus[lastStatus ? lastStatus : ''],
            lastUpdated: new Date().toISOString(),
        })
        await updateOrderDetailStatus(resId, data.order.id, order.detail)
        sound2.play()
    }

    const getDiffTimeOrderDetailStatus = () => {
        const locale = language === 'jp' ? ja : vi
        if (data.order.detail[data.index].status.length === 1) {
            const time1 = convertToDateTime2(data.order.detail[data.index].status[0].lastUpdated)
            const time2 = new Date()
            const diffTime = formatDistance(time1, time2, {
                locale: locale,
            })
            if (diffTime === 'dưới 1 phút') {
                return '0p'
            }
            return diffTime.replaceAll(' phút', 'p')
        }
        const time1 = convertToDateTime2(data.order.detail[data.index].status[data.order.detail[data.index].status.length - 1].lastUpdated)
        const time2 = new Date()
        const diffTime = formatDistance(time1, time2, {
            locale: locale,
        })
        if (diffTime === 'dưới 1 phút') {
            return '0p'
        }
        return diffTime.replaceAll(' phút', 'p')
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setReloadCount((prevCount) => prevCount + 1)
        }, 60 * 1000) // 60000 milliseconds = 1 minute

        return () => clearInterval(intervalId) // Cleanup function to clear the interval on component unmount
    }, []) // Empty dependency array to run the effect only once

    const handleBack = async () => {
        const backStatus: any = {
            pending: 'preparing',
            finished: 'pending',
        }
        const order = data.order
        if (!order?.detail?.[data.index]?.status) {
            order.detail[data.index].status = []
        }
        const lastStatus = order.detail[data.index].status[order.detail[data.index].status.length - 1]?.status

        const lastStatusTime = order.detail[data.index].status[order.detail[data.index].status.length - 2]?.lastUpdated
        order.detail[data.index].status.push({
            status: backStatus[lastStatus ? lastStatus : ''],
            lastUpdated: lastStatusTime,
        })
        await updateOrderDetailStatus(resId, data.order.id, order.detail)
        sound2.play()
    }

    return (
        <Row gutter={4}>
            <Col lg={lastStatus === null || lastStatus === 'preparing' ? 24 : 22} md={lastStatus === null || lastStatus === 'preparing' ? 24 : 20} sm={lastStatus === null || lastStatus === 'preparing' ? 24 : 18} xs={lastStatus === null || lastStatus === 'preparing' ? 24 : 17}>
                <div className={`order-item ${lastStatus === 'preparing' && diffTime >= 15 ? 'danger' : ''}`} onClick={handleChangeStatus}>
                    <div className="order-item__title">
                        <div className="left">{data.order.table_name}</div>
                        <div className="mid">{data.order.number_people}名</div>
                        <div className="right">{dayjs(convertToDateTime(data.order.createdAt)).format('HH:mm')}</div>

                        {lastStatus && <div className="right">{lastStatus === 'pending' || lastStatus === 'finished' ? dayjs(convertToDateTime2(lastStatusTime)).format('HH:mm') : getDiffTimeOrderDetailStatus()}</div>}
                    </div>
                    <div className="order-item__content">
                        <div className="left">
                            <h1>{data.quantity}</h1>
                        </div>
                        <div className="right">
                            <div className="detail">
                                <h3>{data.printName ? data.printName : data.name}</h3>
                                {generateOptions(data)}
                                {generateTopping(data)}
                            </div>
                        </div>
                    </div>
                </div>
            </Col>

            {lastStatus !== null && lastStatus !== 'preparing' && (
                <Col lg={2} md={4} sm={6} xs={7}>
                    <div className="close" onClick={handleBack}>
                        <div className="icon">
                            <ArrowLeftOutlined
                                style={{
                                    fontSize: 20,
                                    fontWeight: 1000,
                                    color: '#2f4ed7',
                                }}
                            />
                        </div>
                    </div>
                </Col>
            )}
        </Row>
    )
}

function convertToDateTime2(time: string) {
    return new Date(time)
}
