import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

interface LoadingProps {
    size?: number
    align?: string
    cover?: string
}

const LoadingDefault: LoadingProps = {
    size: 35,
    align: 'center',
    cover: 'inline',
}

const Loading = (props: LoadingProps) => {
    const data: LoadingProps = {
        ...LoadingDefault,
        ...props,
    }

    const Icon = <LoadingOutlined style={{ fontSize: data.size }} spin />

    return (
        <div className={`loading text-${data.align} cover-${data.cover}`} style={{ position: 'absolute', bottom: "10%", zIndex: 1000 }}>
            <Spin indicator={Icon} />
        </div>
    )
}

export default Loading
