import { Timestamp } from "firebase/firestore";

export default class Utils {
  static getLocalDateTime(serverTimestamp: any) {
    if(!serverTimestamp) return;
    
    return (
      new Date((serverTimestamp?.seconds | serverTimestamp?._seconds) * 1000).toLocaleDateString() +
      " " +
      new Date((serverTimestamp?.seconds | serverTimestamp?._seconds) * 1000).toLocaleTimeString()
    );
  }

  static getLocalDate(serverTimestamp: any) {
    return (
      new Date((serverTimestamp.seconds | serverTimestamp._seconds) * 1000).toLocaleDateString()
    );
  }

  static getLocalTime(serverTimestamp: any) {
    try {
      return new Date((serverTimestamp.seconds | serverTimestamp._seconds) * 1000).toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    } catch {
      return "--:--:--";
    }
  }

  static toNumber(data: any) {
    const res = Number(data);
    if (isNaN(res)) return 0;
    return res;
  }
}

export const numberWithCommas = (number: any) => {
  try {
    return number ? Math.round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "円" : "0 円";
  } catch {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "円" : "0 円";
  }
};

export const convertTimeStringToMinute = (time: any) => {
  if (!time) return;

  let timeSplitColon = time?.split(":");

  return (+timeSplitColon[0]) * 60 + (+timeSplitColon[1]);
};

export const convertTimeMinuteToString = (time: any) => {
  if (!time) return;

  var m = time % 60;

  var h = (time - m) / 60;

  return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
}

export const convertHexToRGBA = (hex: string, alpha: number) =>{
  // Remove '#' symbol if present
  if (!hex) return "";
  hex = hex.replace('#', '');

  // Parse the hexadecimal components
  const red = parseInt(hex.substring(0, 2), 16);
  const green = parseInt(hex.substring(2, 4), 16);
  const blue = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA string
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

const pad2 = (n: any) => {
  return n < 10 ? "0" + n : n;
};
export const getNewOrderId = () => {
  const date = new Date();
  const val =
    date.getFullYear().toString().substring(2,4) +
    pad2(date.getMonth() + 1) +
    pad2(date.getDate()) +
    pad2(date.getHours()) +
    pad2(date.getMinutes()) +
    pad2(date.getSeconds()) + (date.getMilliseconds() + "0").toString().substring(0,3);
  return val;
};

export const isEmptyArray = (array: any[]) => {
  return Array.isArray(array) && array.length === 0
}

export const convertToDateTime = (timestamp: any) => {
  const { seconds, nanoseconds } = timestamp;
  // Convert nanoseconds to milliseconds
  const milliseconds = nanoseconds / 1e6;

  // Create a new Date object using the provided seconds and milliseconds
  const dateTime = new Date(seconds * 1000 + milliseconds);

  return dateTime;
}